import slider2 from '../../imgs/slider2.jpg';
import pacoche from '../../imgs/pacoche.jpg';
import saintMateoBeach from '../../imgs/saint-mateo-beach.webp';
import montecristi from '../../imgs/montecristi.webp';
import losFrailesBeach from '../../imgs/los-frailes-beach.webp';
import fishMarket from '../../imgs/fish-market.jpg';

const Home = () => {
  return (
    <div className='w-full bg-primary flex flex-col items-center gap-4'>
      <div className='relative w-full h-[500px] overflow-hidden'>
        <div className='absolute w-full h-[500px] flex flex-col flex-nowrap'>
          <div className='absolute flex w-full h-full flex-col items-center justify-center text-4xl text-textPrimary font-roboto'>
            Things to do
          </div>
          <div
            className='flex bg-cover bg-center w-full h-[500px]'
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 1), rgba(225, 255, 255, 0.7), rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 1)), url(${slider2})`
            }}
            alt='slider 1'
          />
        </div>
      </div>
      <div className='flex flex-col w-full p-4 sm:w-3/4 gap-4'>
        <div className='text-center text-textPrimary text-2xl font-seaways mt-4'>
          Pacoche
        </div>

        <div>
          <img
            src={pacoche}
            alt='Pacoche'
            className='w-full md:w-1/2 float-right mb-4 md:mx-4 rounded-xl'
          />
          <div className='text-textSecondary text-xl text-justify mb-4'>
            Pacoche, located near Manta on the Pacific coast of Ecuador, is a
            region celebrated for its rich biodiversity and unique microclimate.
            The Pacoche Wildlife Refuge, established in 2008, encompasses
            approximately 13,445 hectares of both land and marine areas,
            featuring lush forests and coastal ecosystems. This protected area
            is a haven for numerous species, including howler and capuchin
            monkeys, over 250 bird species, and various endemic plants.
          </div>
          <div className='text-textSecondary text-xl text-justify mb-4'>
            The refuge offers visitors several activities, such as guided nature
            walks through the rainforest. These trails provide opportunities to
            observe wildlife, including the more visible howler monkeys and the
            elusive capuchin monkeys, as well as a variety of birds and flora
            like the Heliconia flowers and the Toquilla palm used to make Panama
            hats. The best times for these excursions are early mornings or late
            afternoons when wildlife activity is highest.
          </div>
          <div className='text-textSecondary text-xl text-justify'>
            Access to Pacoche from Manta is relatively easy, with the journey
            taking about 20-30 minutes by car. It's advisable to arrange
            transportation in advance, especially if traveling by taxi, to
            ensure return transport.
          </div>
        </div>

        <div className='text-textSecondary text-xl text-justify'>
          Overall, Pacoche offers a blend of ecological richness and cultural
          significance, making it a worthwhile destination for nature
          enthusiasts and those interested in Ecuador's environmental
          conservation efforts.
        </div>
      </div>
      <div className='flex flex-col w-full p-4 sm:w-3/4 gap-4'>
        <div className='text-center text-textPrimary text-2xl font-seaways mt-4'>
          Saint Mateo Beach
        </div>
        <div>
          <img
            src={saintMateoBeach}
            alt='Saint Mateo Beach'
            className='w-full md:w-1/2 float-left mb-4 md:mx-4 rounded-xl'
          />
          <div className='text-textSecondary text-xl text-justify'>
            Saint Mateo Beach is a picturesque and serene destination located in
            Manta, Ecuador. Known for its expansive sandy shores and tranquil
            ambiance, it offers a delightful retreat for visitors seeking a
            blend of relaxation and natural beauty. The beach is a favorite
            among both locals and tourists, offering opportunities for
            sunbathing, swimming, and enjoying the stunning coastal scenery.
          </div>
          <div className='text-textSecondary text-xl text-justify'>
            Features of Saint Mateo Beach
            <ol className='list list-decimal list-inside pl-4 my-4'>
              <li className='text-textSecondary text-xl text-justify mb-4'>
                Natural Beauty: The beach boasts long stretches of fine sand and
                clear blue waters, making it an ideal spot for leisurely walks
                and enjoying the coastal breeze. The natural surroundings
                provide a peaceful environment, perfect for unwinding away from
                the hustle and bustle of urban life.
              </li>
              <li className='text-textSecondary text-xl text-justify mb-4'>
                Activities: Saint Mateo Beach is ideal for various recreational
                activities. Visitors can engage in beach sports, swimming, or
                simply relax under the sun. The gentle waves also make it a
                suitable spot for beginner surfers.
              </li>
              <li className='text-textSecondary text-xl text-justify mb-4'>
                Local Cuisine: Close to the beach, there are several small
                eateries and seafood restaurants where visitors can savor fresh,
                locally caught fish and other coastal delicacies. These dining
                spots add to the beach's charm, offering a taste of the local
                culinary delights.
              </li>
              <li className='text-textSecondary text-xl text-justify mb-4'>
                Accessibility: The beach is easily accessible, with several
                entry points and facilities that cater to tourists. There are
                amenities such as restrooms, showers, and shaded areas, making
                it convenient for families and groups.
                <br />
                <br />
                By Car: The most convenient way to reach Saint Mateo Beach from
                Punta Blanca Urbanization is by car. The journey is relatively
                short, taking approximately 10-15 minutes, depending on traffic
                conditions.
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div className='flex flex-col w-full p-4 sm:w-3/4 gap-4'>
        <div className='text-center text-textPrimary text-2xl font-seaways mt-4'>
          Montecristi
        </div>
        <div>
          <img
            src={montecristi}
            alt='Montecristi'
            className='w-full md:w-1/2 float-right mb-4 md:mx-4 rounded-xl'
          />
          <div className='text-textSecondary text-xl text-justify'>
            Montecristi, located near Manta, Ecuador, is a town rich in history
            and cultural significance. Known for its traditional crafts,
            particularly the famous Panama hats, Montecristi offers visitors a
            unique glimpse into Ecuadorian heritage and artisanal excellence.
          </div>
          <div className='text-textSecondary text-xl text-justify'>
            Features of Montecristi
            <ol className='list list-decimal list-inside pl-4 my-4'>
              <li className='text-textSecondary text-xl text-justify mb-4'>
                Panama Hat Craftsmanship: Despite the name, Panama hats
                originated in Ecuador, and Montecristi is renowned for producing
                some of the finest. The town's skilled artisans hand-weave these
                hats from toquilla straw, a tradition passed down through
                generations. Visitors can tour workshops to see the meticulous
                process and purchase high-quality, authentic Panama hats.
              </li>
              <li className='text-textSecondary text-xl text-justify mb-4'>
                Historical and Cultural Sites: Montecristi is home to several
                notable landmarks, including the Eloy Alfaro Museum, dedicated
                to the former president and national hero, and the beautiful
                Basílica Menor de la Virgen de Monserrate, a significant
                religious site. These attractions provide insights into the
                region's history and cultural fabric.
              </li>
              <li className='text-textSecondary text-xl text-justify mb-4'>
                Artisan Markets: The town has vibrant markets where visitors can
                buy a variety of handcrafted goods, including woven items,
                ceramics, and other traditional crafts. These markets are a
                great place to find unique souvenirs and support local artisans.
              </li>
              <li className='text-textSecondary text-xl text-justify mb-4'>
                Scenic Views and Natural Beauty: Situated on a hillside,
                Montecristi offers stunning views of the surrounding landscape.
                The town’s elevation provides a cooler climate and picturesque
                vistas, making it a pleasant destination to explore.
              </li>
            </ol>
            <div className='text-textSecondary text-xl text-justify'>
              How to Reach Montecristi from Punta Blanca Urbanization
              <br />
              <br />
              Punta Blanca Urbanization is located in Manta, and reaching
              Montecristi from this urban area is straightforward and
              convenient. Here are the best ways to get there: By Car: Driving
              is the most efficient way to travel from Punta Blanca Urbanization
              to Montecristi. The trip typically takes around 20-30 minutes.
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-col w-full p-4 sm:w-3/4 gap-4'>
        <div className='text-center text-textPrimary text-2xl font-seaways mt-4'>
          Los Frailes Beach
        </div>
        <div>
          <img
            src={losFrailesBeach}
            alt='Los Frailes Beach'
            className='w-full md:w-1/2 float-left mb-4 md:mx-4 rounded-xl'
          />
          <div className='text-textSecondary text-xl text-justify'>
            Los Frailes Beach, located within the Machalilla National Park near
            Manta, Ecuador, is often considered one of the most beautiful
            beaches in the country. Known for its stunning natural beauty, clear
            waters, and serene environment, Los Frailes Beach offers a perfect
            escape for nature lovers and beachgoers seeking tranquility.
          </div>
          <div className='text-textSecondary text-xl text-justify'>
            Features of Los Frailes Beach
            <ol className='list list-decimal list-inside pl-4 my-4'>
              <li className='text-textSecondary text-xl text-justify mb-4'>
                Pristine Natural Beauty: Los Frailes Beach is renowned for its
                untouched beauty. The beach features soft, white sands,
                crystal-clear turquoise waters, and lush green hills surrounding
                it. The area is well-preserved, with minimal human impact,
                providing a serene and unspoiled environment.
              </li>
              <li className='text-textSecondary text-xl text-justify mb-4'>
                Recreational Activities: Visitors to Los Frailes Beach can enjoy
                swimming, snorkeling, and sunbathing. The clear waters are ideal
                for spotting marine life, and the beach’s gentle waves make it a
                safe spot for swimming. Hiking trails around the beach offer
                spectacular viewpoints and opportunities to explore the diverse
                flora and fauna of Machalilla National Park.
              </li>
              <li className='text-textSecondary text-xl text-justify mb-4'>
                Wildlife and Ecology: As part of Machalilla National Park, Los
                Frailes Beach is a haven for biodiversity. The park is home to
                various species of birds, reptiles, and marine life. Eco-tourism
                activities are popular, including guided tours that educate
                visitors about the local ecosystem and conservation efforts.
              </li>
              <li className='text-textSecondary text-xl text-justify mb-4'>
                Peaceful Atmosphere: Unlike more commercialized beaches, Los
                Frailes maintains a peaceful and relaxed atmosphere. There are
                no large hotels or restaurants directly on the beach, preserving
                its natural state. Basic amenities, such as restrooms and shaded
                areas, are available at the park entrance.
              </li>
            </ol>
            <div className='text-textSecondary text-xl text-justify'>
              How to Reach Los Frailes Beach from Punta Blanca Urbanization
              <br />
              <br />
              Punta Blanca Urbanization is located in Manta, and reaching Los
              Frailes Beach involves a scenic drive along the coast. Here’s a
              guide to help you get there:
              <br />
              <br />
              By Car: Driving is the most convenient way to travel from Punta
              Blanca Urbanization to Los Frailes Beach. The trip typically takes
              around 1.5 to 2 hours.
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-col w-full p-4 sm:w-3/4 gap-4'>
        <div className='text-center text-textPrimary text-2xl font-seaways mt-4'>
          The fish Market
        </div>
        <div>
          <img
            src={fishMarket}
            alt='The fish Market'
            className='w-full mb-4 rounded-xl'
          />

          <div className='text-textSecondary text-xl text-justify'>
            The fish market in Manta, Ecuador, is a bustling and vibrant hub of
            activity, showcasing the city's rich maritime culture and its
            importance as one of the largest ports on the Pacific coast of South
            America. Known locally as the "Mercado de Mariscos," this market is
            a must-visit destination for those interested in experiencing the
            local lifestyle and savoring the freshest seafood.
          </div>
          <div className='text-textSecondary text-xl text-justify'>
            Features of the Fish Market in Manta
            <ol className='list list-decimal list-inside pl-4 my-4'>
              <li className='text-textSecondary text-xl text-justify mb-4'>
                Fresh Seafood: The market is famous for its wide variety of
                fresh seafood. Fishermen bring in their catch daily, offering
                everything from fish, shrimp, and crabs to octopus, squid, and
                other exotic marine creatures. The seafood is often sold
                directly from the boats, ensuring unparalleled freshness.
              </li>
              <li className='text-textSecondary text-xl text-justify mb-4'>
                Local Cuisine: The market isn't just a place to buy raw seafood;
                it's also a culinary destination. Numerous stalls and small
                eateries within the market prepare traditional Ecuadorian
                seafood dishes such as ceviche, fried fish, and seafood stews.
                Visitors can enjoy these meals while taking in the lively market
                atmosphere.
              </li>
              <li className='text-textSecondary text-xl text-justify mb-4'>
                Cultural Experience: Visiting the fish market offers a unique
                glimpse into the daily life of Manta's residents. The vibrant
                atmosphere, the hustle and bustle of traders, and the friendly
                interactions between buyers and sellers reflect the city's
                maritime heritage and community spirit.
              </li>
              <li className='text-textSecondary text-xl text-justify mb-4'>
                Variety of Products: In addition to seafood, the market also
                sells a variety of other products, including fresh fruits,
                vegetables, and spices. This makes it a convenient spot for both
                locals and tourists to shop for a wide range of fresh produce.
              </li>
            </ol>
            <div className='text-textSecondary text-xl text-justify'>
              How to Reach the Fish Market from Punta Blanca Urbanization
              <br />
              <br />
              Punta Blanca Urbanization is located within Manta, and reaching
              the fish market from this residential area is straightforward.
              Here’s how you can get there:
              <br />
              <br />
              By Car: Driving is the most convenient way to reach the fish
              market from Punta Blanca Urbanization. The journey typically takes
              about 15-20 minutes, depending on traffic conditions.
            </div>
          </div>
        </div>
      </div>
      <div className='bg-fuchsia-950 w-full flex h-20 text-textPrimary items-center justify-center'>
        <div className=' flex h-20 text-textPrimary items-center'>
          © 2023 puntablancamanta.com
        </div>
      </div>
    </div>
  );
};

export default Home;
