import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import NavBar from './components//NavBar';
import MobileNavBar from './components/MobileNavBar';

function App() {
  const [isNavBarOpen, setIsNavBarOpen] = useState(false);

  const animation = '-translate-x-3/4 sm:translate-x-0';

  return (
    <div className='flex flex-col  w-full'>
      <div className='flex flex-row justify-between w-full'>
        <NavBar setIsNavBarOpen={setIsNavBarOpen} />
        <div
          className={`w-full h-full absolute right-0 left-0 top-0 z-0 ease-out duration-700 ${
            (isNavBarOpen && animation) || ''
          }`}
        >
          <MobileNavBar isOpen={isNavBarOpen} handleOnOpen={setIsNavBarOpen} />

          <div className='flex w-full sm:pt-40'>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
