import React from 'react';
import ReactDOM from 'react-dom/client';

import './i18n';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Home from './components/Home';
import Gallery from './components/Gallery';
import Booking from './components/Booking';
import Tours from './components/Tours';

import 'lightbox.js-react/dist/index.css';
import 'react-slideshow-image/dist/styles.css';
import './index.css';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/',
        element: <Home />
      },
      {
        path: '/list',
        element: <Booking />
      },
      {
        path: '/ocean5',
        element: <Gallery type='ocean5' />
      },
      {
        path: '/ocean12',
        element: <Gallery type='ocean12' />
      },
      {
        path: '/booking',
        element: <Booking isBooking />
      },
      {
        path: '/tours',
        element: <Tours />
      }
    ]
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
