import c5 from '../../imgs/c5.jpeg';
import a12 from '../../imgs/a12.webp';
import { Link } from 'react-router-dom';

import slider2 from '../../imgs/slider2.jpg';

const Booking = ({ isBooking = false }) => {
  return (
    <div className='w-full bg-primary flex flex-col items-center gap-4'>
      <div className='relative w-full h-[500px] overflow-hidden'>
        <div className='absolute w-full h-[500px] flex flex-col flex-nowrap'>
          <div className='absolute flex w-full h-full flex-col items-center justify-center text-4xl text-textPrimary font-roboto'>
            {isBooking ? 'Booking' : 'Our Homes'}
          </div>
          <div
            className='flex bg-cover bg-center w-full h-[500px]'
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 1), rgba(225, 255, 255, 0.7), rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 1)), url(${slider2})`
            }}
            alt='slider 1'
          />
        </div>
      </div>
      <div className='flex gap-6 justify-center flex-col sm:flex-row w-3/4'>
        <Link
          target='_blank'
          rel='noreferrer'
          to={
            isBooking
              ? 'https://www.airbnb.ca/rooms/51892765?locale=en&_set_bev_on_new_domain=1700370747_OTllN2M2ZjBhOTAx&source_impression_id=p3_1700439549_tDtRjzf75d3V5iLe'
              : '/ocean5'
          }
          className='hover:text-textSecondary w-full sm:w-1/2'
        >
          <div className='flex flex-col text-center'>
            <div
              className='rounded-xl h-64 bg-cover duration-300 hover:bg-[200%] border-4 border-textPrimary'
              style={{
                backgroundImage: `url(${c5})`
              }}
            ></div>

            <div className='mt-2'>
              <span className='font-sans text-4xl'>O</span>
              <span className='font-sans text-2xl'>cean 5</span>
            </div>
          </div>
        </Link>

        <Link
          target='_blank'
          rel='noreferrer'
          to={
            isBooking
              ? 'https://www.airbnb.ca/rooms/53080588?locale=en&_set_bev_on_new_domain=1700370747_OTllN2M2ZjBhOTAx&source_impression_id=p3_1700439550_9w1qKeoPb921Th%2F4'
              : '/ocean12'
          }
          className='hover:text-textPrimary w-full sm:w-1/2'
        >
          <div className='flex flex-col text-center'>
            <div
              className='rounded-xl h-64 bg-cover duration-300 hover:bg-[200%] border-4 border-textSecondary'
              style={{
                backgroundImage: `url(${a12})`
              }}
            ></div>
            <div className='mt-2'>
              <span className='font-sans  text-4xl'>O</span>
              <span className='font-sans text-2xl'>cean 12</span>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Booking;
