import { GlobalOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import logo from '../../logo.webp';
import { Link } from 'react-router-dom';

const NavBar = ({ setIsNavBarOpen }) => {
  const { t, i18n } = useTranslation();
  const handleLanguageChange = () => {
    i18n.changeLanguage(i18n.language === 'en' ? 'es' : 'en');
  };

  return (
    <div className='flex justify-between items-center p-4 text-lg text-textPrimary absolute top-0 right-0 sm:top-auto sm:right-auto sm:relative h-full sm:h-48 w-3/4 sm:w-full  bg-primary z-0 sm:z-10'>
      <div className='hidden sm:block'>
        <Link to='/'>
          <img src={logo} alt={t('punta-blanca')} className='p-2 h-48' />
        </Link>
      </div>
      <div className='flex flex-col p-4 sm:p-0 items-start sm:justify-end sm:gap-4 sm:flex-row sm:items-center h-full'>
        <div className='py-2 sm:p-4 hover:cursor-pointer hover:text-gray-400'>
          <Link onClick={() => setIsNavBarOpen(false)} to='/'>
            {t('home')}
          </Link>
        </div>
        <div className='py-2 sm:p-4 hover:cursor-pointer hover:text-gray-400'>
          <Link onClick={() => setIsNavBarOpen(false)} to='/list'>
            Our Homes
          </Link>
        </div>
        <div className='py-2 sm:p-4 hover:cursor-pointer hover:text-gray-400'>
          <Link onClick={() => setIsNavBarOpen(false)} to='/booking'>
            {t('booking')}
          </Link>
        </div>
        <div className='py-2 sm:p-4 hover:cursor-pointer hover:text-gray-400'>
          <Link onClick={() => setIsNavBarOpen(false)} to='/tours'>
            Things to do
          </Link>
        </div>
        <div
          className='p-2 hover:cursor-pointer border rounded hover:bg-white hover:text-black'
          onClick={handleLanguageChange}
        >
          <GlobalOutlined className='icon' /> / {i18n.language.toUpperCase()}
        </div>
      </div>
    </div>
  );
};

export default NavBar;
