import React, { useState } from 'react';
import FsLightbox from 'fslightbox-react';
import PhotoAlbum from 'react-photo-album';

import c51 from '../../imgs/C5/1.jpeg';
import c52 from '../../imgs/C5/2.jpeg';
import c53 from '../../imgs/C5/3.webp';
import c54 from '../../imgs/C5/4.webp';
import c55 from '../../imgs/C5/5.jpeg';
import c56 from '../../imgs/C5/6.jpeg';
import c57 from '../../imgs/C5/7.jpeg';
import c58 from '../../imgs/C5/8.jpeg';
import c59 from '../../imgs/C5/9.jpeg';
import c510 from '../../imgs/C5/10.jpg';
import c511 from '../../imgs/C5/11.jpeg';
import c512 from '../../imgs/C5/12.jpeg';
import c513 from '../../imgs/C5/13.jpeg';
import c514 from '../../imgs/C5/14.jpeg';
import c515 from '../../imgs/C5/15.jpeg';
import c516 from '../../imgs/C5/16.jpeg';
import c517 from '../../imgs/C5/17.jpeg';
import c518 from '../../imgs/C5/18.jpeg';
import c519 from '../../imgs/C5/19.jpeg';
import c520 from '../../imgs/C5/20.jpeg';
import c521 from '../../imgs/C5/21.jpeg';
import c522 from '../../imgs/C5/22.jpeg';
import c523 from '../../imgs/C5/23.webp';
import c524 from '../../imgs/C5/24.webp';
import c525 from '../../imgs/C5/25.jpeg';
import c526 from '../../imgs/C5/26.jpg';
import c527 from '../../imgs/C5/27.jpeg';
import c528 from '../../imgs/C5/28.webp';
import c529 from '../../imgs/C5/29.webp';
import c530 from '../../imgs/C5/30.jpeg';
import c531 from '../../imgs/C5/31.webp';
import c532 from '../../imgs/C5/32.jpeg';
import c533 from '../../imgs/C5/33.jpeg';
import c534 from '../../imgs/C5/34.jpeg';
import c535 from '../../imgs/C5/35.jpeg';
import c536 from '../../imgs/C5/36.jpeg';
import c537 from '../../imgs/C5/37.jpeg';
import c538 from '../../imgs/C5/38.jpeg';
import c539 from '../../imgs/C5/39.jpg';
import c540 from '../../imgs/C5/40.jpeg';
import c541 from '../../imgs/C5/41.jpeg';
import c542 from '../../imgs/C5/42.webp';
import c543 from '../../imgs/C5/43.webp';
import c544 from '../../imgs/C5/44.jpeg';
import c545 from '../../imgs/C5/45.webp';
import c546 from '../../imgs/C5/46.webp';
import c547 from '../../imgs/C5/47.jpeg';
import c548 from '../../imgs/C5/48.webp';
import c549 from '../../imgs/C5/49.jpeg';
import c550 from '../../imgs/C5/50.jpeg';
import c551 from '../../imgs/C5/51.jpeg';
import c552 from '../../imgs/C5/52.jpeg';
import c553 from '../../imgs/C5/53.webp';
import c554 from '../../imgs/C5/54.webp';
import c555 from '../../imgs/C5/55.webp';
import c556 from '../../imgs/C5/56.jpeg';
import c557 from '../../imgs/C5/57.jpeg';
import c558 from '../../imgs/C5/58.webp';
import c559 from '../../imgs/C5/59.jpeg';
import c560 from '../../imgs/C5/60.jpeg';
import c561 from '../../imgs/C5/61.webp';
import c562 from '../../imgs/C5/62.jpeg';
import c563 from '../../imgs/C5/63.jpeg';
import c564 from '../../imgs/C5/64.jpeg';
import c565 from '../../imgs/C5/65.webp';
import c566 from '../../imgs/C5/66.jpeg';
import c567 from '../../imgs/C5/67.jpeg';
import c568 from '../../imgs/C5/68.jpeg';
import c569 from '../../imgs/C5/69.jpeg';

import a121 from '../../imgs/A12/1.jpeg';
import a122 from '../../imgs/A12/2.webp';
import a123 from '../../imgs/A12/3.webp';
import a124 from '../../imgs/A12/4.webp';
import a125 from '../../imgs/A12/5.webp';
import a126 from '../../imgs/A12/6.webp';
import a127 from '../../imgs/A12/7.webp';
import a128 from '../../imgs/A12/8.webp';
import a129 from '../../imgs/A12/9.webp';
import a1210 from '../../imgs/A12/10.webp';
import a1211 from '../../imgs/A12/11.webp';
import a1212 from '../../imgs/A12/12.webp';
import a1213 from '../../imgs/A12/13.webp';
import a1214 from '../../imgs/A12/14.jpeg';
import a1215 from '../../imgs/A12/15.webp';
import a1216 from '../../imgs/A12/16.jpeg';
import a1217 from '../../imgs/A12/17.jpeg';
import a1218 from '../../imgs/A12/18.webp';
import a1219 from '../../imgs/A12/19.webp';
import a1220 from '../../imgs/A12/20.webp';
import a1221 from '../../imgs/A12/21.jpeg';
import a1222 from '../../imgs/A12/22.webp';
import a1223 from '../../imgs/A12/23.webp';
import a1224 from '../../imgs/A12/24.webp';
import a1225 from '../../imgs/A12/25.webp';
import a1226 from '../../imgs/A12/26.webp';
import a1227 from '../../imgs/A12/27.webp';
import a1228 from '../../imgs/A12/28.webp';
import a1229 from '../../imgs/A12/29.webp';
import a1230 from '../../imgs/A12/30.webp';
import a1231 from '../../imgs/A12/31.webp';
import a1232 from '../../imgs/A12/32.webp';
import a1233 from '../../imgs/A12/33.webp';
import a1234 from '../../imgs/A12/34.webp';
import a1235 from '../../imgs/A12/35.webp';
import a1236 from '../../imgs/A12/36.jpeg';
import a1237 from '../../imgs/A12/37.webp';
import a1238 from '../../imgs/A12/38.webp';
import a1239 from '../../imgs/A12/39.webp';
import a1240 from '../../imgs/A12/40.webp';
import a1241 from '../../imgs/A12/41.webp';
import a1242 from '../../imgs/A12/42.webp';
import a1243 from '../../imgs/A12/43.webp';
import a1244 from '../../imgs/A12/44.webp';
import a1245 from '../../imgs/A12/45.webp';
import a1246 from '../../imgs/A12/46.webp';
import a1247 from '../../imgs/A12/47.webp';
import a1248 from '../../imgs/A12/48.webp';
import a1249 from '../../imgs/A12/49.jpeg';
import a1250 from '../../imgs/A12/50.webp';
import a1251 from '../../imgs/A12/51.webp';
import a1252 from '../../imgs/A12/52.webp';
import a1253 from '../../imgs/A12/53.jpeg';
import a1254 from '../../imgs/A12/54.webp';
import a1255 from '../../imgs/A12/55.webp';
import a1256 from '../../imgs/A12/56.webp';
import a1257 from '../../imgs/A12/57.webp';
import a1258 from '../../imgs/A12/58.webp';
import a1259 from '../../imgs/A12/59.webp';
import a1260 from '../../imgs/A12/60.webp';

const gallery = {
  ocean5: [
    { src: c51, width: 1.5, height: 1 },
    { src: c52, width: 1.5, height: 1 },
    { src: c53, width: 1.5, height: 1 },
    { src: c54, width: 1.5, height: 1 },
    { src: c55, width: 1.5, height: 1 },
    { src: c56, width: 1.5, height: 1 },
    { src: c57, width: 1.5, height: 1 },
    { src: c58, width: 1.5, height: 1 },
    { src: c59, width: 1.5, height: 1 },
    { src: c510, width: 1.5, height: 1 },
    { src: c511, width: 1.5, height: 1 },
    { src: c512, width: 1.5, height: 1 },
    { src: c513, width: 1.5, height: 1 },
    { src: c514, width: 1.5, height: 1 },
    { src: c515, width: 1.5, height: 1 },
    { src: c516, width: 1.5, height: 1 },
    { src: c517, width: 1.5, height: 1 },
    { src: c518, width: 1.5, height: 1 },
    { src: c519, width: 1.5, height: 1 },
    { src: c520, width: 1.5, height: 1 },
    { src: c521, width: 1.5, height: 1 },
    { src: c522, width: 1.5, height: 1 },
    { src: c523, width: 1.5, height: 1 },
    { src: c524, width: 1.5, height: 1 },
    { src: c525, width: 1.5, height: 1 },
    { src: c526, width: 1.5, height: 1 },
    { src: c527, width: 1.5, height: 1 },
    { src: c528, width: 1.5, height: 1 },
    { src: c529, width: 1.5, height: 1 },
    { src: c530, width: 1.5, height: 1 },
    { src: c531, width: 1.5, height: 1 },
    { src: c532, width: 1.5, height: 1 },
    { src: c533, width: 1.5, height: 1 },
    { src: c534, width: 1.5, height: 1 },
    { src: c535, width: 1.5, height: 1 },
    { src: c536, width: 1.5, height: 1 },
    { src: c537, width: 1.5, height: 1 },
    { src: c538, width: 1.5, height: 1 },
    { src: c539, width: 1.5, height: 1 },
    { src: c540, width: 1.5, height: 1 },
    { src: c541, width: 1.5, height: 1 },
    { src: c542, width: 1.5, height: 1 },
    { src: c543, width: 1.5, height: 1 },
    { src: c544, width: 1.5, height: 1 },
    { src: c545, width: 1.5, height: 1 },
    { src: c546, width: 1.5, height: 1 },
    { src: c547, width: 1.5, height: 1 },
    { src: c548, width: 1.5, height: 1 },
    { src: c549, width: 1.5, height: 1 },
    { src: c550, width: 1.5, height: 1 },
    { src: c551, width: 1.5, height: 1 },
    { src: c552, width: 1.5, height: 1 },
    { src: c553, width: 1.5, height: 1 },
    { src: c554, width: 1.5, height: 1 },
    { src: c555, width: 1.5, height: 1 },
    { src: c556, width: 1.5, height: 1 },
    { src: c557, width: 1.5, height: 1 },
    { src: c558, width: 1.5, height: 1 },
    { src: c559, width: 1.5, height: 1 },
    { src: c560, width: 1.5, height: 1 },
    { src: c561, width: 1.5, height: 1 },
    { src: c562, width: 1.5, height: 1 },
    { src: c563, width: 1.5, height: 1 },
    { src: c564, width: 1.5, height: 1 },
    { src: c565, width: 1.5, height: 1 },
    { src: c566, width: 1.5, height: 1 },
    { src: c567, width: 1.5, height: 1 },
    { src: c568, width: 1.5, height: 1 },
    { src: c569, width: 1.5, height: 1 }
  ],
  ocean12: [
    { src: a121, width: 1.5, height: 1 },
    { src: a122, width: 1.5, height: 1 },
    { src: a123, width: 1.5, height: 1 },
    { src: a124, width: 1.5, height: 1 },
    { src: a125, width: 1.5, height: 1 },
    { src: a126, width: 1.5, height: 1 },
    { src: a127, width: 1.5, height: 1 },
    { src: a128, width: 1.5, height: 1 },
    { src: a129, width: 1.5, height: 1 },
    { src: a1210, width: 1.5, height: 1 },
    { src: a1211, width: 1.5, height: 1 },
    { src: a1212, width: 1.5, height: 1 },
    { src: a1213, width: 1.5, height: 1 },
    { src: a1214, width: 1.5, height: 1 },
    { src: a1215, width: 1.5, height: 1 },
    { src: a1216, width: 1.5, height: 1 },
    { src: a1217, width: 1.5, height: 1 },
    { src: a1218, width: 1.5, height: 1 },
    { src: a1219, width: 1.5, height: 1 },
    { src: a1220, width: 1.5, height: 1 },
    { src: a1221, width: 1.5, height: 1 },
    { src: a1222, width: 1.5, height: 1 },
    { src: a1223, width: 1.5, height: 1 },
    { src: a1224, width: 1.5, height: 1 },
    { src: a1225, width: 1.5, height: 1 },
    { src: a1226, width: 1.5, height: 1 },
    { src: a1227, width: 1.5, height: 1 },
    { src: a1228, width: 1.5, height: 1 },
    { src: a1229, width: 1.5, height: 1 },
    { src: a1230, width: 1.5, height: 1 },
    { src: a1231, width: 1.5, height: 1 },
    { src: a1232, width: 1.5, height: 1 },
    { src: a1233, width: 1.5, height: 1 },
    { src: a1234, width: 1.5, height: 1 },
    { src: a1235, width: 1.5, height: 1 },
    { src: a1236, width: 1.5, height: 1 },
    { src: a1237, width: 1.5, height: 1 },
    { src: a1238, width: 1.5, height: 1 },
    { src: a1239, width: 1.5, height: 1 },
    { src: a1240, width: 1.5, height: 1 },
    { src: a1241, width: 1.5, height: 1 },
    { src: a1242, width: 1.5, height: 1 },
    { src: a1243, width: 1.5, height: 1 },
    { src: a1244, width: 1.5, height: 1 },
    { src: a1245, width: 1.5, height: 1 },
    { src: a1246, width: 1.5, height: 1 },
    { src: a1247, width: 1.5, height: 1 },
    { src: a1248, width: 1.5, height: 1 },
    { src: a1249, width: 1.5, height: 1 },
    { src: a1250, width: 1.5, height: 1 },
    { src: a1251, width: 1.5, height: 1 },
    { src: a1252, width: 1.5, height: 1 },
    { src: a1253, width: 1.5, height: 1 },
    { src: a1254, width: 1.5, height: 1 },
    { src: a1255, width: 1.5, height: 1 },
    { src: a1256, width: 1.5, height: 1 },
    { src: a1257, width: 1.5, height: 1 },
    { src: a1258, width: 1.5, height: 1 },
    { src: a1259, width: 1.5, height: 1 },
    { src: a1260, width: 1.5, height: 1 }
  ]
};

function Gallery({ type }) {
  // To open the lightbox change the value of the "toggler" prop.
  const [toggler, setToggler] = useState(false);

  console.log(gallery[type]);

  return (
    <div className='w-3/4 m-auto '>
      <div onClick={() => setToggler(!toggler)} className=''>
        <PhotoAlbum layout='rows' photos={gallery[type]} />
      </div>
      <FsLightbox
        toggler={toggler}
        sources={gallery[type].map((image) => image.src)}
      />
    </div>
  );
}

export default Gallery;
