import { useTranslation } from 'react-i18next';
import { Zoom } from 'react-slideshow-image';

import slider1 from '../../imgs/slider1.jpg';
import slider2 from '../../imgs/slider2.jpg';
import slider3 from '../../imgs/slider3.jpg';
import slider4 from '../../imgs/slider4.jpg';
import slider5 from '../../imgs/slider5.jpg';
import slider6 from '../../imgs/slider6.jpg';
import things1 from '../../imgs/things1.webp';
import things2 from '../../imgs/things2.webp';
import things3 from '../../imgs/things3.webp';
import { useState } from 'react';
import axios from 'axios';
import c5 from '../../imgs/c5.jpeg';
import a12 from '../../imgs/a12.webp';
import { Link } from 'react-router-dom';

const Home = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    from: '',
    name: '',
    message: ''
  });
  const [isEmailSent, setIsEmailSent] = useState(false);

  return (
    <div className='w-full bg-primary flex flex-col items-center gap-4'>
      <div className='relative w-full h-[500px] overflow-hidden'>
        <div className='absolute w-full h-[500px] flex flex-col flex-nowrap'>
          <Zoom
            arrows={false}
            autoplay
            infinite
            duration={3000}
            scale={1.3}
            zoomIn
          >
            <div
              className='flex bg-cover bg-center w-full h-[500px]'
              style={{
                backgroundImage: `linear-gradient(rgba(255, 255, 255, 1), rgba(225, 255, 255, 0.3), rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)), url(${slider1})`
              }}
              alt='slider 1'
            />
            <div
              className='flex bg-cover bg-center w-full h-[500px]'
              style={{
                backgroundImage: `linear-gradient(rgba(255, 255, 255, 1), rgba(225, 255, 255, 0.3), rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)), url(${slider2})`
              }}
              alt='slider 1'
            />
            <div
              className='flex bg-cover bg-center w-full h-[500px]'
              style={{
                backgroundImage: `linear-gradient(rgba(255, 255, 255, 1), rgba(225, 255, 255, 0.3), rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)), url(${slider3})`
              }}
              alt='slider 1'
            />
            <div
              className='flex bg-cover bg-center w-full h-[500px]'
              style={{
                backgroundImage: `linear-gradient(rgba(255, 255, 255, 1), rgba(225, 255, 255, 0.3), rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)), url(${slider4})`
              }}
              alt='slider 1'
            />
            <div
              className='flex bg-cover bg-center w-full h-[500px]'
              style={{
                backgroundImage: `linear-gradient(rgba(255, 255, 255, 1), rgba(225, 255, 255, 0.3), rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)), url(${slider5})`
              }}
              alt='slider 1'
            />
            <div
              className='flex bg-cover bg-center w-full h-[500px]'
              style={{
                backgroundImage: `linear-gradient(rgba(255, 255, 255, 1), rgba(225, 255, 255, 0.3), rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)), url(${slider6})`
              }}
              alt='slider 1'
            />
          </Zoom>
        </div>
      </div>
      <div className='flex flex-col w-full p-4 sm:w-3/4 gap-4'>
        <div className='text-center text-textPrimary text-2xl font-seaways mt-4'>
          {t('welcome')} <span className='font-sans'>{t('welcomeTo')}</span>
        </div>
        <div className='text-textSecondary text-xl text-justify'>
          {t('welcomeText')}
        </div>
        <div className='flex gap-6 justify-center flex-col sm:flex-row'>
          <Link
            target='_blank'
            rel='noreferrer'
            to='https://www.airbnb.ca/rooms/51892765?locale=en&_set_bev_on_new_domain=1700370747_OTllN2M2ZjBhOTAx&source_impression_id=p3_1700439549_tDtRjzf75d3V5iLe'
            className='hover:text-textSecondary w-full sm:w-1/2'
          >
            <div className='flex flex-col text-center'>
              <div
                className='rounded-xl h-64 bg-cover duration-300 hover:bg-[200%] border-4 border-textPrimary'
                style={{
                  backgroundImage: `url(${c5})`
                }}
              ></div>

              <div className='mt-2'>
                <span className='font-sans text-4xl'>O</span>
                <span className='font-sans text-2xl'>cean 5</span>
              </div>
            </div>
          </Link>

          <Link
            target='_blank'
            rel='noreferrer'
            to={
              'https://www.airbnb.ca/rooms/53080588?locale=en&_set_bev_on_new_domain=1700370747_OTllN2M2ZjBhOTAx&source_impression_id=p3_1700439550_9w1qKeoPb921Th%2F4'
            }
            className='hover:text-textPrimary w-full sm:w-1/2'
          >
            <div className='flex flex-col text-center'>
              <div
                className='rounded-xl h-64 bg-cover duration-300 hover:bg-[200%] border-4 border-textSecondary'
                style={{
                  backgroundImage: `url(${a12})`
                }}
              ></div>
              <div className='mt-2'>
                <span className='font-sans  text-4xl'>O</span>
                <span className='font-sans text-2xl'>cean 12</span>
              </div>
            </div>
          </Link>
        </div>
        <div className='bg-slate-600 h-[1px] my-4' />
        <div className='text-center text-textPrimary text-2xl font-seaways mt-4'>
          {t('things')}
          <span className='font-sans'>{t('thingsToDo')}</span>
        </div>
        <div className='flex flex-col sm:flex-row gap-4 text-xl'>
          <div className='flex flex-col gap-4'>
            <div
              className='bg-cover bg-center w-full h-[300px]'
              style={{ backgroundImage: `url(${things2})` }}
            ></div>
            <span className=' text-textPrimary'>Playa El Murcielago Manta</span>
            <div className='bg-slate-600 h-[1px] my-4' />
            <span className='text-textSecondary'>
              {t('playaElMurcielagoManta')}
            </span>
          </div>
          <div className='flex flex-col gap-4'>
            <div
              className='bg-cover bg-center w-full h-[300px]'
              style={{ backgroundImage: `url(${things1})` }}
            ></div>
            <span className=' text-textPrimary'>Playa Puerto Lopez</span>

            <div className='bg-slate-600 h-[1px] my-4' />
            <span className='text-textSecondary'>{t('playaPuertoLopez')}</span>
          </div>
          <div className='flex flex-col  gap-4'>
            <div
              className='bg-cover bg-center w-full h-[300px]'
              style={{ backgroundImage: `url(${things3})` }}
            ></div>
            <span className=' text-textPrimary'>Playa Santa Marianita</span>

            <div className='bg-slate-600 h-[1px] my-4' />
            <span className='text-textSecondary'>
              {t('playaSantaMarianita')}
            </span>
          </div>
        </div>
        <div className='bg-slate-600 h-[1px] my-4' />
        <div className='flex flex-col justify-left gap-4 w-full'>
          <div className='text-center text-textPrimary text-2xl font-seaways mt-4'>
            {t('frequently')} <span className='font-sans'>{t('FAQ')}</span>
          </div>
          <div className='flex flex-col justify-left gap-4'>
            <span className='text-textPrimary text-xl w-full'>
              {t('howDoIBook')}
            </span>
            <span className='text-textSecondary text-xl w-full'>
              {t('howDoIBookAnswer')}
            </span>
            <span className='text-textPrimary text-xl w-full'>
              {t('whereDoWeStay')}
            </span>
            <span className='text-textSecondary text-xl w-full'>
              {t('whereDoWeStayAnswer')}
            </span>
            <span className='text-textPrimary text-xl w-full'>
              {t('areTheToursSuitableForAllAges')}
            </span>
            <span className='text-textSecondary text-xl w-full'>
              {t('areTheToursSuitableForAllAgesAnswer')}
            </span>
          </div>
        </div>
        <div className='bg-slate-600 h-[1px] my-4' />
        <div className='flex flex-col justify-left gap-4'>
          <span className='text-textPrimary text-xl'>{t('review')}</span>
          <span className='text-textPrimary'>— John Smith</span>
        </div>
        <div className='bg-slate-600 h-[1px] my-4' />
        <div className='flex flex-col sm:flex-row gap-4 w-full'>
          <form
            onSubmit={async (e) => {
              e.preventDefault();

              if (formData.name && formData.message && formData.from) {
                const data = await axios.post(
                  `https://api.puntablancamanta.com/booking/email`,
                  formData
                );

                if (data.data.msg === 'the email was sent') {
                  setIsEmailSent(true);
                }
              }
            }}
            className='flex flex-col justify-left gap-4 text-textPrimary w-full sm:w-1/2'
          >
            <div className='text-center text-textPrimary text-2xl font-seaways mt-4'>
              {t('contact')} <span className='font-sans'>{t('us')}</span>
            </div>
            <span className='text-textPrimary'>{t('forInquiries')}</span>
            <label className='flex flex-col gap-1 w-full'>
              {t('name')}
              <input
                required
                className='w-full p-2 text-textPrimary border rounded-xl border-textSecondary'
                value={formData.name}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    name: e.target.value
                  });
                }}
              />
            </label>
            <label className='flex flex-col gap-1'>
              {t('emailAddress')}
              <input
                required
                className='w-full p-2 text-textPrimary border rounded-xl border-textSecondary'
                value={formData.from}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    from: e.target.value
                  });
                }}
              />
            </label>
            <label className='flex flex-col gap-1'>
              {t('message')}
              <textarea
                required
                className='w-full p-2 h-40 text-textPrimary border rounded-xl border-textSecondary'
                value={formData.message}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    message: e.target.value
                  });
                }}
              />
            </label>
            <div className='text-textSecondary'>
              {isEmailSent && 'Enquiry has been sent successfully'}
            </div>
            <button
              className='self-center w-full sm:w-1/2 rounded bg-cyan-600 p-5 text-textPrimary'
              type='submit'
            >
              {t('submit')}
            </button>
          </form>

          <div className='flex flex-col justify-center gap-4 text-textPrimary w-full sm:w-1/2'>
            <div className='text-center text-textPrimary text-2xl font-seaways mt-4'>
              {t('location')}
            </div>
            <div className='w-full'>
              <iframe
                src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3054.904745755608!2d-80.64856209649638!3d-0.9444861046838943!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x902be78d19691925%3A0x521e7e57b3263f0b!2sPUNTA%20BLANCA%20URBANIZACI%C3%93N%20Y%20CLUB%20DE%20PLAYA!5e0!3m2!1sen!2sca!4v1705263287661!5m2!1sen!2sca'
                className='w-full'
                height='450'
                allowFullScreen=''
                loading='lazy'
                referrerPolicy='no-referrer-when-downgrade'
                title='map'
              ></iframe>
            </div>
            <div classme='text-left text-textPrimary '>
              Punta Blanca Urb.
              <br />
              Vía Manta - Jaramijo, Manta, Ecuador
            </div>
          </div>
        </div>
        <div className='bg-slate-600 h-[1px]  my-4' />
        <div className='text-center text-textPrimary text-2xl font-seaways mt-4'>
          {t('about')} <span className='font-sans'>{t('us')}</span>
        </div>
        <div className='text-center text-textSecondary text-xl '>
          {t('aboutUsDetails')}
        </div>
      </div>
      <div className='bg-fuchsia-950 w-full flex h-20 text-textPrimary items-center justify-center'>
        <div className=' flex h-20 text-textPrimary items-center'>
          © 2023 puntablancamanta.com
        </div>
      </div>
    </div>
  );
};

export default Home;
